<template>
  <ElTable
    :data="archiveList"
    :show-header="true"
    ref="tableRow"
    style="width: 100%"
    :stripe="isZebra"
    :row-style="rowStyle"
  >
    <ElTableColumn
      v-for="(item, idx) in statesTable"
      :key="idx"
      :property="item.prop"
      :label="item.label"
      :min-width="item.width"
      :header-align="item.headerAlign"
      :align="item.align"
    >
      <template slot="header">
        <div class="flex items-center" :class="[`justify-${item.align}`]">
          <span class="text-xs text-black-100 font-normal"> {{ item.label }} </span>
          {{item.orderSort}}
          <SortIcon
            v-if="item.icon"
            :order-sort="item.orderSort"
            class="ml-1 cursor-pointer"
            @click.native="$emit('sort-change',idx)"
          />
        </div>
      </template>
      <template slot-scope="scope">
        <div v-if="item.prop === 'name'" class="flex items-center gap-2 leading-4 truncate">
          <h3 :class="{'text-base': stateStyle === '1', 'text-sm': stateStyle === '2'}" class="name font-semibold">
            {{scope.row.name}}
          </h3>
          <div class="flex items-center justify-center bg-green-100 w-16 text-xs text-white rounded h-5" v-if="scope.row?.isCurrent">
            {{ $t('archive.current') }}
          </div>
        </div>
        <div
          v-if="item.prop === 'state_unit.terminal_type.value'"
          class="flex items-center leading-4 truncate">
          <h3 :class="{'text-sm': stateStyle === '1', 'text-xs': stateStyle === '2'}" class="font-normal text-grey-300 grey-small-text">
            {{ scope.row.state_unit.terminal_type.value }}
          </h3>
        </div>
        <div
          v-if="item.prop === 'date_from'"
          class="flex items-center leading-4 truncate"
        >
          <h3 :class="{'text-sm': stateStyle === '1', 'text-xs': stateStyle === '2'}" class="font-normal text-grey-300 grey-small-text">{{scope.row.date_from}}</h3>
        </div>
        <div
          v-if="item.prop === 'state_unit.imei'"
          class="flex items-center leading-4 truncate"
        >
          <h3 :class="{'text-sm': stateStyle === '1', 'text-xs': stateStyle === '2'}" class="font-normal text-grey-300 grey-small-text">{{scope.row.state_unit.imei}}</h3>
        </div>
        <div v-else-if="item.prop === 'settings'" class="flex items-center justify-center w-full">
          <ElPopover
            v-if="showPopover"
            placement="bottom"
            trigger="hover"
            :key="popoverKey"
            :visible-arrow="false"
            popper-class="setting-popover"
            :append-to-body="true"
          >
            <template slot="reference">
              <div class="options_wrapper">
                <svg
                  width="20"
                  height="11"
                  viewBox="0 0 4 11"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.761719 1.36035C0.761719 1.71191 0.887695 2.01074 1.13965 2.25684C1.3916 2.50879 1.69336 2.63477 2.04492 2.63477C2.39648 2.63477 2.69824 2.50879 2.9502 2.25684C3.20215 2.00488 3.32812 1.70605 3.32812 1.36035C3.32812 1.00293 3.20215 0.698242 2.9502 0.446289C2.69824 0.200195 2.39648 0.0771484 2.04492 0.0771484C1.69336 0.0771484 1.3916 0.200195 1.13965 0.446289C0.887695 0.698242 0.761719 1.00293 0.761719 1.36035ZM0.761719 5.45605C0.761718 5.80762 0.887695 6.10937 1.13965 6.36133C1.3916 6.61328 1.69336 6.73926 2.04492 6.73926C2.39648 6.73926 2.69824 6.61328 2.9502 6.36133C3.20215 6.10937 3.32812 5.80762 3.32812 5.45605C3.32812 5.10449 3.20215 4.80273 2.9502 4.55078C2.69824 4.30469 2.39648 4.18164 2.04492 4.18164C1.69336 4.18164 1.3916 4.30469 1.13965 4.55078C0.887695 4.80273 0.761719 5.10449 0.761719 5.45605ZM0.761718 9.56055C0.761718 9.91211 0.887695 10.2109 1.13965 10.457C1.3916 10.709 1.69336 10.835 2.04492 10.835C2.39648 10.835 2.69824 10.709 2.95019 10.457C3.20215 10.2109 3.32812 9.91211 3.32812 9.56055C3.32812 9.20898 3.20215 8.90723 2.95019 8.65527C2.69824 8.40332 2.39648 8.27734 2.04492 8.27734C1.69336 8.27734 1.3916 8.40332 1.13965 8.65527C0.887695 8.90723 0.761718 9.20898 0.761718 9.56055Z"
                    fill="#3A7CCA"
                  />
                </svg>
              </div>
            </template>

            <template v-if="showPopover">
              <div class="flex flex-col justify-between h-[152px] w-[289px] bg-white shadow-lg rounded-lg bg-lightGrey-200 overflow-hidden">
                <span
                  class="p-1 transition-all ease-in-out duration-300 text-mainTitleColor bg-lightGrey-200 hover_text-blue-200 hover_bg-white cursor-pointer state-action"
                  @click="editStateArchive(scope.row)">
                  {{ $t('archive.state_edit') }}
                </span>
                <span
                  class="p-1 transition-all ease-in-out duration-300 text-mainTitleColor bg-lightGrey-200 hover_text-blue-200 hover_bg-white cursor-pointer state-action"
                  @click="copyAndSetCurrent(scope.row)">
                  {{ $t('archive.state_copy') }}
                </span>
                <span
                  class="p-1 transition-all ease-in-out duration-300 text-red-100 bg-lightGrey-200 hover_bg-white cursor-pointer state-action"
                  @click="deleteStateArchive(scope.row)">
                  {{ $t('archive.state_delete') }}
                </span>
              </div>
            </template>

          </ElPopover>
          <div v-else class="options_wrapper">
            <svg
              width="20"
              height="11"
              viewBox="0 0 4 11"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.761719 1.36035C0.761719 1.71191 0.887695 2.01074 1.13965 2.25684C1.3916 2.50879 1.69336 2.63477 2.04492 2.63477C2.39648 2.63477 2.69824 2.50879 2.9502 2.25684C3.20215 2.00488 3.32812 1.70605 3.32812 1.36035C3.32812 1.00293 3.20215 0.698242 2.9502 0.446289C2.69824 0.200195 2.39648 0.0771484 2.04492 0.0771484C1.69336 0.0771484 1.3916 0.200195 1.13965 0.446289C0.887695 0.698242 0.761719 1.00293 0.761719 1.36035ZM0.761719 5.45605C0.761718 5.80762 0.887695 6.10937 1.13965 6.36133C1.3916 6.61328 1.69336 6.73926 2.04492 6.73926C2.39648 6.73926 2.69824 6.61328 2.9502 6.36133C3.20215 6.10937 3.32812 5.80762 3.32812 5.45605C3.32812 5.10449 3.20215 4.80273 2.9502 4.55078C2.69824 4.30469 2.39648 4.18164 2.04492 4.18164C1.69336 4.18164 1.3916 4.30469 1.13965 4.55078C0.887695 4.80273 0.761719 5.10449 0.761719 5.45605ZM0.761718 9.56055C0.761718 9.91211 0.887695 10.2109 1.13965 10.457C1.3916 10.709 1.69336 10.835 2.04492 10.835C2.39648 10.835 2.69824 10.709 2.95019 10.457C3.20215 10.2109 3.32812 9.91211 3.32812 9.56055C3.32812 9.20898 3.20215 8.90723 2.95019 8.65527C2.69824 8.40332 2.39648 8.27734 2.04492 8.27734C1.69336 8.27734 1.3916 8.40332 1.13965 8.65527C0.887695 8.90723 0.761718 9.20898 0.761718 9.56055Z"
                fill="#3A7CCA"
              />
            </svg>
          </div>
        </div>
      </template>
    </ElTableColumn>
  </ElTable>
</template>

<script setup>
import { ref } from "vue";
import SortIcon from '@/components/unitsNew/assets/icons/sort.vue'

const props = defineProps({
  archiveList:{
    type: Array,
    default: []
  },
  statesTable:{
    type: Array,
    default: []
  },
  isZebra:{
    type: Boolean,
    default: false
  },
  rowStyle:{
    type: Object,
  },
  stateStyle:{
    type: String,
    default: 1
  },
})

const popoverKey = ref(0);
const showPopover = ref(true);

const emits = defineEmits(['onCopyAndSetCurrent', 'onEdit', 'onDelete']);

const copyAndSetCurrent = (row) => {
  closePopover();
  emits('onCopyAndSetCurrent',row);
}

const editStateArchive = (row) => {
  closePopover();
  emits('onEdit', row);
}

const deleteStateArchive = (row) => {
  closePopover();
  emits('onDelete', row)
}

const closePopover = () => {
  showPopover.value = false;
  popoverKey.value++;
  setTimeout(() => {
    document.querySelectorAll('[role="tooltip"]').forEach(function (el){
      el.remove();
    });
    showPopover.value = true;
  }, 1500)
}
</script>

<style lang="scss" scoped>
.name {
  max-width: 210px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: 'Source Sans Pro', serif;
  color: #171717;
  font-size: 16px;
  font-weight: 600;
  line-height: 19.2px;
}


::v-deep(.el-popover){
  border: none;
}

.state-action {
  padding: 6px 12px;
}

.state-action:first-child {
  padding-top: 10px;
}

.state-action:last-child {
  padding-bottom: 10px;
}

.grey-small-text {
  font-family: 'Source Sans Pro', serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 19.6px;
  color: #828D9B;
}
</style>
